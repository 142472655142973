<template>
  <div>
    <!-- Page Header -->
    <PageHeader :title="title" :items="items" />
    <!-- Project Info -->
    <div class="d-sm-flex justify-content-between align-items-center">
      <div class="mb-3">
        <div class="mr-2 px-1">
          <span class="project-name">{{ projectName }}</span>
        </div>
        <div class="mr-2 dita-ot-cont px-1">
          <span class="dita-ot">DITA-OT Version:</span>
          <span class="dita-ot-version ml-2">{{ ditaOtVersions }}</span>
        </div>
      </div>
      <div v-if="repobranchesdata.length">
        <button class="btn btn-primary btn-sm mr-3 mb-2" type="button" @click="navigateToDiff">
          <span class="d-flex align-items-center justify-content-center">
            <span>
              <i class="mdi mdi-file-compare mdi-16px"></i>
            </span>
            <span class="ml-2"> Compare branches </span>
          </span>
        </button>
        <button v-if="ditaTreeData && ditaTreeData[0]?.children?.length > 0" class="btn btn-primary btn-sm mr-3 mb-2"
          type="submit" @click="downloadZip()">
          <span class="d-flex align-items-center justify-content-center">
            <span>
              <i class="mdi mdi-content-save mdi-16px"></i>
            </span>
            <span class="ml-2"> Download Source Files </span>
          </span>
        </button>
        <button v-if="
          projectName !== 'AI-Deployment' &&
          isDocEditor &&
          ditaTreeData &&
          ditaTreeData[0]?.children?.length > 0
        " class="btn btn-primary btn-sm mb-2" type="submit" @click="openEditor()">
          <span class="d-flex align-items-center justify-content-center">
            <span>
              <i class="mdi mdi-file-edit mdi-16px"></i>
            </span>
            <span class="ml-2"> DocEditor </span>
          </span>
        </button>
      </div>
    </div>
    <!-- Branches Dropdown -->
    <div class="row mb-2 mt-3">
      <button v-if="projectName !== 'AI-Deployment'" class="btn btn-primary btn-sm ml-3 mb-2"
        @click="$bvModal.show('changeBranch-bv-modal')">
        Create new branch
      </button>

      <div class="col-lg-12">
        <multiselect :max-height="150" v-model="brachName" :options="repobranchesdata.map((item) => item.text)"
          placeholder="Choose a branch" :allow-empty="false">
        </multiselect>
        <!-- <select
          class="form-control"
          v-model="brachName"
          placeholder="Select Branch"
          @change="getObject"
        >
          <option value="" disabled>Please Choose Branch</option>
          <option
            v-for="(option, index) in repobranchesdata"
            :key="index"
            :value="option.value"
          >
            {{ option.text }}
          </option>
        </select> -->
      </div>
    </div>
    <!-- Source and Target -->
    <div class="row">
      <!-- Source Section -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="custom-source d-flex justify-content-between align-items-center flex-wrap">
                      <div class="custom-title mb-0">Source</div>
                    </div>

                    <div class="border-top mt-1 infoDiv folderStructure">
                      <simplebar v-if="
                        ditaTreeData && ditaTreeData[0]?.children?.length > 0
                      " class="pt-1 custom-jstree p-3">
                        <v-jstree :data="ditaTreeData" @item-click="itemClick"></v-jstree>
                      </simplebar>
                      <template v-else>
                        <div class="d-flex justify-content-center align-items-center no-output-cont">
                          <div class="mb-5 no-output-div">
                            <img class="custom-img" src="../../../assets/placeholder.png" />
                            <div class="no-output-text">
                              Repository is empty.
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3 mt-md-0" v-if="ditaTreeData && ditaTreeData[0]?.children?.length > 0">
                    <div class="custom-source d-flex justify-content-between align-items-center flex-wrap">
                      <div class="custom-title mb-0">Content Preview</div>
                    </div>
                    <div class="border-top mt-1 infoDiv" v-if="displayDitaContent">
                      <simplebar class="custom-jstree p-3">
                        <ssh-pre :language="contentTypeFormat">
                          {{ displayDitaContent }}
                        </ssh-pre>
                      </simplebar>
                    </div>
                    <template v-else>
                      <div class="d-flex justify-content-center align-items-center no-output-cont">
                        <div class="mb-5 no-output-div">
                          <img class="custom-img" src="../../../assets/placeholder.png" />
                          <div class="no-output-text">No file selected.</div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <changeBranch :branches="repobranchesdata.map((element) => element.value)"
      :createNewBranchHandler="createNewBranchHandler" :clone="true" />
  </div>
</template>
<script>
import simplebar from "simplebar-vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import checkurl from "../../../components/urlvalidator";
import PageHeader from "@/components/pageheader";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CryptoJS from "crypto-js";
import { eventBus } from "../../../main";
import { secretKey } from "../../../api/global.env";
import xmlFormat from "xml-formatter";
import VJstree from "vue-jstree";
import SshPre from "simple-syntax-highlighter";
import "simple-syntax-highlighter/dist/sshpre.css";
import githubService from "../../../services/github";
import Multiselect from "vue-multiselect";
import changeBranch from "../../../components/reusables/changeBranch.vue";
import { decryptParam } from "../../../services/cryptoFunction";
export default {
  components: {
    VJstree,
    SshPre,
    PageHeader,
    simplebar,
    Multiselect,
    changeBranch,
  },
  computed: {
    ...mapGetters(["ditaOtVersions"]),
  },
  data() {
    return {
      title: "DocManager",
      items: [
        {
          text: "Projects",
          href: `/docmanager`,
        },
        {
          text: "DocManager",
          active: true,
        },
      ],
      treeData: [],
      contentTypeFormat: "xml",
      repobranchesdata: [],
      ditaotVersion: "",
      projectName: CryptoJS.AES.decrypt(
        this.$route.params.reponame,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      repouser: CryptoJS.AES.decrypt(
        this.$route.params.repouser,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      isLoading: false,
      downloadURL: "",
      brachName: "",
      previousSelectedBranch: "",
      urlParserRegex: /^[/]([^/]+)[/]([^/]+)[/]tree[/]([^/]+)[/](.*)/,
      outputURL: null,
      gitToken: this.$store.state.Auth.gitToken,
      isDocEditor: null,
      isDocPublisher: null,
      editor: ClassicEditor,
      editorData: null,
      editorLoader: false,
      modal: false,
      userId: this.$store.state.Auth.userId,
      isDitaFilesPresent: false,
      ditaTreeData: [],
      selectedFile: null,
      displayDitaContent: null,
      ignoreNextChange: false, // Flag to ignore next change
    };
  },

  async mounted() {
    eventBus.$emit("update-sidebar", "menuitems.docmanager.text");
    this.getRepoBranch();
    this.getOrgDetails();
    this.getfoldertree();
  },
  methods: {
    navigateToDiff() {
      const encryptedRepouser = CryptoJS.AES.encrypt(
        this.repouser,
        secretKey
      ).toString();
      const encryptedReponame = CryptoJS.AES.encrypt(
        this.projectName,
        secretKey
      ).toString();
      const encryptedCurrentBranch = CryptoJS.AES.encrypt(
        this.brachName,
        secretKey
      ).toString();
      const encodedRepouser = encodeURIComponent(encryptedRepouser);
      const encodedReponame = encodeURIComponent(encryptedReponame);
      const encodedCurrentBranch = encodeURIComponent(encryptedCurrentBranch);

      this.$router.push(`/docmanager/${encodedRepouser}/${encodedReponame}/${encodedCurrentBranch}/diff`);
    },
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    validateURL() {
      const newRepoUser = CryptoJS.AES.decrypt(
        this.$route.params.repouser,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      const newRepoName = CryptoJS.AES.decrypt(
        this.$route.params.reponame,
        secretKey
      ).toString(CryptoJS.enc.Utf8);
      const oldRepoUser = localStorage.getItem("repouser");
      const oldRepoName = localStorage.getItem("reponame");
      if (newRepoName !== oldRepoName || newRepoUser !== oldRepoUser) {
        checkurl(newRepoName);
      }
    },
    async openEditor() {
      if (this.$store.state.Auth.projectsData.length) {
        this.navigateToEditor();
      } else {
        this.userId = this.$store.state.Auth.userId;
        this.$store.getters.client
          .get(`/projectuser/byuserid?userId=${this.userId}`)
          .then((res) => {
            this.$store.commit("setProjectsData", res.data);
            this.navigateToEditor();
          })
          .catch(() => { });
      }
    },
    navigateToEditor() {
      console.log(this.repouser, this.projectName, this.brachName);
      const encryptedRepouser = CryptoJS.AES.encrypt(
        this.repouser,
        secretKey
      ).toString();
      const encryptedReponame = CryptoJS.AES.encrypt(
        this.projectName,
        secretKey
      ).toString();
      const encryptedBranch = CryptoJS.AES.encrypt(
        this.brachName,
        secretKey
      ).toString();
      const encodedRepouser = encodeURIComponent(encryptedRepouser);
      const encodedReponame = encodeURIComponent(encryptedReponame);
      const encodedBranch = encodeURIComponent(encryptedBranch);
      this.$router.push({
        path: `/doceditor/${encodedRepouser}/${encodedReponame}/${encodedBranch}`,
      });
    },
    handleAccessDenied() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary btn-sm mr-2",
          cancelButton: "btn btn-light btn-sm",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons.fire({
        icon: "error",
        title: "Oops...",
        text: "Sorry, you do not have access to DocEditor. Please contact your administrator if you would like to access this feature. Thank you.",
      });
    },

    async getOrgDetails() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      let orgId = this.$store.state.Auth.orgId;
      this.$store.getters.client
        .get(`serveradmin/organization/byorgid?orgId=${orgId}`)
        .then((res) => {
          loader.hide();
          if (res.data && res.data.length > 0) {
            // Check if data exists and if it's in the expected format
            this.isDocEditor = res.data[0].editor;
            this.isDocPublisher = res.data[0].publisher;
          } else {
            // Handle an invalid response
            this.messageToast("Invalid request", "danger", "An error occurred");
          }
        })
        .catch((err) => {
          alert(err.message);
          loader.hide();
          // Handle network issues or unexpected errors
          this.messageToast(
            "Invalid request",
            "danger",
            err.response ? err.response.data.message : "An error occurred"
          );
        });
    },
    // Get all branches inside repository
    async getRepoBranch() {
      let loader = this.$loading.show({
        loader: "dots",
      });

      this.$store.getters.client
        .get(
          `orguser/repobranches?repoUser=${this.repouser}&repoName=${this.projectName}`
        )
        .then(async (branchResponse) => {
          if (this.$route.query.branchName) {
            this.brachName = decryptParam(this.$route.query.branchName);
            this.previousSelectedBranch = this.brachName;
            this.getObject(this.brachName);
            this.$router.push({});
          } else {
            const res = await githubService.currentBranch(this.projectName);
            this.brachName = res.data;
            this.previousSelectedBranch = res.data;
          }

          if (branchResponse.data && branchResponse.data.length > 0) {
            this.repobranchesdata = branchResponse.data.map((element) => ({
              value: element.name,
              text: element.name,
            }));
            this.outputURL = `https://github.com/${this.repouser}/${this.projectName}/tree/${this.brachName}/output`;

            return this.$store.getters.client.get(
              `project/outputtree?gitUsername=${this.repouser}&gitReponame=${this.projectName}&branchsha=${this.brachName}`
            );
          } else {
            this.messageToast(
              "Invalid request",
              "danger",
              "No branches found in the repository."
            );
          }
        })
        .then((outputResponse) => {
          if (outputResponse.data) {
            this.treeData = outputResponse.data;
          } else {
            this.messageToast(
              "Invalid request",
              "danger",
              "No data found in the output tree."
            );
          }
        })
        .catch((err) => {
          // Handle errors here, e.g., show an error message or log the error.
          this.messageToast(
            "Invalid request",
            "danger",
            err.response
              ? err.response.data.message
              : "An error occurred while fetching data."
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
    // Get tree of output folder in particular branch
    async getObject(branch) {
      console.log("📢[details.vue:414]: branch: ", branch);
      this.brachName = branch;

      // ============checkout the branch============

      const res = await githubService.checkout(
        {
          repoName: this.projectName,
          branchName: this.brachName,
        },
        this
      );
      console.log("ckeckout res: ", res);

      if (res === false) {
        this.messageToast(
          "Invalid request",
          "danger",
          "Failed to checkout branch."
        );
        this.brachName = this.previousSelectedBranch;
        this.ignoreNextChange = true; // Set the flag to ignore next change
        return;
      } else {
        this.previousSelectedBranch = this.brachName;
        this.messageToast("Branch checkout", "success", res.message);
      }
      // ============checkout the branch============

      let loader = this.$loading.show({
        loader: "dots",
      });

      this.outputURL = `https://github.com/${CryptoJS.AES.decrypt(
        this.$route.params.repouser,
        secretKey
      ).toString(CryptoJS.enc.Utf8)}/${CryptoJS.AES.decrypt(
        this.$route.params.reponame,
        secretKey
      ).toString(CryptoJS.enc.Utf8)}/tree/${this.brachName}/bin/output`;
      this.$store.getters.client
        .get(
          `project/outputtree?gitUsername=${CryptoJS.AES.decrypt(
            this.$route.params.repouser,
            secretKey
          ).toString(CryptoJS.enc.Utf8)}&gitReponame=${CryptoJS.AES.decrypt(
            this.$route.params.reponame,
            secretKey
          ).toString(CryptoJS.enc.Utf8)}&branchsha=${this.brachName}`
        )
        .then((res) => {
          loader.hide();
          if (res.data) {
            // Check if data exists
            this.treeData = res.data;
          } else {
            // Handle an invalid response
            this.messageToast("Invalid request", "danger", "An error occurred");
          }
        })
        .catch((err) => {
          loader.hide();
          // Handle network issues or unexpected errors
          this.messageToast(
            "Invalid request",
            "danger",
            err.response ? err.response.data.message : "An error occurred"
          );
        });
      this.selectedFile = null;
      this.displayDitaContent = null;
      await this.getfoldertree();
      await this.getIndentedContent();
    },

    // create new branch
    async createNewBranchHandler({ baseBranchName, newBranchName }) {
      let loadingInstance = this.$loading.show({
        loader: "dots",
      }); // Show loading
      const branches = this.repobranchesdata.map((element) => element.value);
      const res = await githubService.createNewBranch(
        {
          repoName: this.projectName,
          clone: true,
          baseBranchName,
          newBranchName,
        },
        branches
      );
      console.log("res", res);
      loadingInstance.hide(); // Hide loading
      this.$bvModal.hide("changeBranch-bv-modal");

      if (res.type === "success") {
        this.selectedFile = null;
        this.displayDitaContent = null;
        await this.getRepoBranch();
        await this.getfoldertree();
        await this.getIndentedContent();
        this.messageToast("New branch", "success", res.data.message);
      } else {
        this.messageToast(
          "Failed to create new branch.",
          "danger",
          res.error.response.data.message
        );
      }
    },

    async fetchRepoInfo(repo) {
      try {
        const response = await fetch(
          `https://api.github.com/repos/${repo}`,
          this.gitToken
            ? {
              headers: {
                Authorization: `Bearer ${this.gitToken}`,
              },
            }
            : {}
        );
        if (!response.ok) {
          throw new Error(`HTTP ${response.statusText}`);
        }
        return response.json();
      } catch (error) {
        // Handle errors and display an error message
        this.messageToast(
          "Invalid request",
          "danger",
          "Error fetching repository info"
        );
        throw error; // Re-throw the error to be handled at a higher level if needed
      }
    },
    escapeFilepath(path) {
      return path.replaceAll("#", "%23");
    },
    hasDitaOrDitamapFile(tree) {
      // Helper function to check if a name has a .dita or .ditamap extension
      function hasDitaOrDitamapExtension(name) {
        return name.endsWith(".dita") || name.endsWith(".ditamap");
      }

      // Recursive function to traverse the tree
      function traverse(node) {
        if (hasDitaOrDitamapExtension(node.name)) {
          return true;
        }
        if (node.children && node.children.length > 0) {
          for (let child of node.children) {
            if (traverse(child)) {
              return true;
            }
          }
        }
        return false;
      }

      // Start the traversal from each root node in the tree array
      for (let root of tree) {
        if (traverse(root)) {
          return true;
        }
      }

      return false;
    },

    addFieldsToNestedArray(array) {
      array.forEach((item) => {
        //  save index.ditamap as selected file
        const fileExt = item?.name.split(".").pop();
        if (
          (fileExt == "ditamap" || item?.name == "index.html") &&
          !this.selectedFile
        ) {
          this.selectedFile = item;
          this.getIndentedContent();
          item.selected = true;
        }

        // Add text and icon fields
        item.text = item?.name;

        item.isDirectory = item?.children?.length > 0;
        item.icon = item.isDirectory
          ? "fa-solid fa-folder-open custom-structure-img"
          : "fa-solid fa-file-lines custom-structure-img";

        // Exclude ".git" and "output" children
        if (item?.children?.length) {
          item.children = item.children.filter(
            (child) =>
              ![".git", "README.md", "images", "log.txt"].includes(child.name)
          );
          // Recursively call the function on the filtered children
          this.addFieldsToNestedArray(item.children);
        }

        // Set the "opened" property based on conditions
        if ([".git", "output"].includes(item.name)) {
          item.opened = false;
        } else {
          item.opened = true;
        }
      });

      return array;
    },
    async getfoldertree() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .get(
          `/orguser/workspace/byuserId?userId=${this.$store.state.Auth.userId}`
        )
        .then(async (res) => {
          let path = res.data.installedPath + `/${this.projectName}`;

          await this.$store.getters.client
            .get(`/orguser/workspace/repotree?path=${path}`)
            .then((tree) => {
              this.ditaTreeData = this.addFieldsToNestedArray([tree.data]);

              this.isDitaFilesPresent = this.hasDitaOrDitamapFile([tree.data]);
            })
            .catch(() => { });
        })
        .catch(() => { })
        .finally(() => {
          loader.hide();
        });
    },
    itemClick(node) {
      if (
        node.model.name.endsWith(".dita") ||
        node.model.name.endsWith(".ditamap") ||
        node.model.name.endsWith(".html") ||
        node.model.name.endsWith(".css") ||
        node.model.name.endsWith(".js")
      ) {
        this.selectedFile = node.model;
        this.getIndentedContent();
      } else {
        this.selectedFile = null;
      }
      const treeSelectedElements = document.querySelectorAll(".tree-selected");
      treeSelectedElements.forEach(function (el) {
        el.classList.remove("tree-selected");
      });
    },
    xmlFormat,
    getIndentedContent() {
      console.log("selected file: ", this.selectedFile);
      const ext = this.selectedFile.name.split(".").pop();

      if (
        ext.startsWith("dita") ||
        ext.startsWith("xml") ||
        ext.startsWith("html") ||
        ext.startsWith("css") ||
        ext.startsWith("js")
      ) {
        if (["dita", "ditamap", "xml", "html"].includes(ext)) {
          this.contentTypeFormat = "xml";
        } else if (["css", "js"].includes(ext)) {
          this.contentTypeFormat = "css";
        }
        this.$store.getters.client
          .get(`/orguser/workspace/filecontent?path=${this.selectedFile.path}`)
          .then((res) => {
            this.displayDitaContent = res.data;
          });
      } else {
        this.displayDitaContent = null;
      }
    },
    async downloadZip() {
      let user;
      let repository;
      try {
        console.log("object url", this.outputURL);
        const parsedUrl = new URL(this.outputURL);
        [, user, repository] = this.urlParserRegex.exec(parsedUrl.pathname);
      } catch (err) {
        // Handle URL parsing error
        this.messageToast("Invalid request", "danger", "Error parsing URL");
        return;
      }
      let loader = this.$loading.show({
        loader: "dots",
      });
      await this.$store.getters.client
        .post(
          "/orguser/downloadSourceFiles",
          {
            owner: user, //Project Owner name
            projectName: repository,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", repository + ".zip");
          document.body.appendChild(link);
          link.click();
          loader.hide();
          this.messageToast("Success", "primary", "Downloaded successfull!");
        })
        .catch((error) => {
          loader.hide();
          this.messageToast("Invalid request", "danger", error.message);
        });
    },
    downloadRepoContent() {
      let user;
      let repository;
      try {
        const parsedUrl = new URL(this.outputURL);
        [, user, repository] = this.urlParserRegex.exec(parsedUrl.pathname);
      } catch (err) {
        // Handle URL parsing error
        this.messageToast("Invalid request", "danger", "Error parsing URL");
        return;
      }
      const url = `https://github.com/${user}/${repository}/archive/refs/heads/main.zip`;
      window.open(url, "_blank");
    },
  },
  watch: {
    brachName(newVal, oldValue) {
      // Ignore the next change if we're reverting the value
      if (this.ignoreNextChange) {
        this.ignoreNextChange = false; // Reset the flag
        return;
      }
      if (oldValue && newVal) {
        console.log("in brachName: ", newVal, oldValue);
        this.getObject(newVal);
      }
    },
  },
};
</script>
<style scoped>
.custom-img {
  height: 200px;
}

.custom-source {
  padding: 14px;
  gap: 24px;
}

.custom-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.project-name {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(23, 35, 61, 1);
}

.dita-ot-cont {
  margin-top: 0.4rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.dita-ot {
  color: rgba(23, 35, 61, 1);
}

.dita-ot-version {
  color: rgba(105, 111, 121, 1);
}

label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.btabs-height {
  height: 100%;
}

/* Media query for Z Fold when the screen is folded */
@media screen and (max-width: 280px) {
  .font-size-15 {
    font-size: 8px !important;
  }
}

.card-body {
  padding: 5px 0px 5px 0px;
}

.custom-jstree {
  height: 60vh;
  overflow: auto;
}

.source-card,
.target-card {
  height: 60vh;
}

.source-card .media-body,
.target-card .media-body {
  background: #007bff;
}

.no-output-cont {
  height: 60vh;
}

.no-output-text {
  color: #666;
  font-size: 20px;
}

.no-output-div {
  text-align: center;
}

.no-output {
  height: 60vh;
  text-align: center;
  color: #666;
  font-size: 20px;
}

.height-100 {
  height: 100% !important;
}

.gap-3 {
  gap: 0.75rem;
}

.pointer {
  cursor: pointer;
}

.custom-doceditor-btn {
  float: right;
}

.download-field {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin-top: 2rem;
  margin: 2rem;
  border: 1.5px dashed rgba(15, 52, 96, 1);
  border-radius: 8px;
  background-color: rgba(113, 165, 203, 0.05);
}

.download-btn {
  text-align: center;
  cursor: pointer;
}

.download-text {
  display: block;
  font-size: 1.8rem;
  font-family: "Roboto";
}

.download-icon {
  display: block;
  height: zz;
  margin: 0 auto;
}

.downloadImg {
  display: block;
  margin: 0 auto;
  height: 64px;
  width: 64px;
}

.download-field .download-btn span {
  font-size: 24px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: center;
  color: rgba(23, 35, 61, 1);
}

img {
  height: 32px;
  margin-bottom: 0px;
}

/* Media query for Z Fold when the screen is folded */
@media screen and (max-width: 280px) {
  body {
    font-size: 15px;
  }

  .downloadImg {
    height: 5rem;
  }

  .btn {
    font-size: 10px;
    height: 20px;
    margin: 0;
    padding: 2px 4px;
  }

  .font-size-15 {
    font-size: 9px !important;
  }

  img {
    height: 200px;
  }
}
</style>
<style>
.custom-structure-img {
  width: 18px !important;
  height: 18px !important;
}

.custom-jstree {
  background-color: #00000006;
}

.ssh-pre {
  border: none;
  background-color: transparent;
}

.infoDiv.folderStructure .tree-container-ul {
  width: 100vw;
}
</style>
