<template>
  <div class="pr-comparison-container">
    <h2 class="page-heading">Compare Branches</h2>

    <div class="pr-header">
      <span class="branch-label">Base:</span>
      <multiselect
        v-model="baseBranch"
        :options="branches"
        placeholder="Select branch"
        label="name"
        track-by="name"
        class="branch-multiselect"
        :disabled="!branches.length || isLoading"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          {{ option.name || "Select branch" }}
        </template>
      </multiselect>

      <i class="mdi mdi-arrow-left-right arrow-icon"></i>

      <span class="branch-label">Target:</span>
      <multiselect
        v-model="headBranch"
        :options="filteredBranches"
        placeholder="Select branch"
        label="name"
        track-by="name"
        class="branch-multiselect"
        :disabled="!branches.length || isLoading"
      >
        <template slot="singleLabel" slot-scope="{ option }">
          {{ option.name || "Select branch" }}
        </template>
      </multiselect>

      <button class="compare-btn" @click="fetchDiff" :disabled="isLoading">
        <i class="mdi mdi-file-compare mdi-16px"></i> Compare
      </button>
    </div>

    <div v-if="isLoading" class="loader-container">
      <i class="mdi mdi-loading mdi-spin mdi-36px"></i>
      <p>Fetching data...</p>
    </div>

    <button
      v-if="originalDiff && !isLoading"
      class="toggle-btn"
      :class="{ active: diffFormat === 'side-by-side' }"
      @click="toggleDiffView"
    >
      <i
        class="mdi"
        :class="
          diffFormat === 'side-by-side'
            ? 'mdi-toggle-switch'
            : 'mdi-toggle-switch-off'
        "
      ></i>
      {{ diffFormat === "side-by-side" ? "Split View" : "Unified View" }}
    </button>

    <div
      v-if="originalDiff && !isLoading"
      class="custom-diff-container"
      v-html="computedDiffHtml"
    ></div>

    <div v-if="!originalDiff && !isLoading" class="no-changes-container">
      <i class="mdi mdi-information-outline mdi-36px"></i>
      <p>There isn’t anything to compare.</p>
      <span>Select different branches to compare.</span>
    </div>
  </div>
</template>

<script>
import * as Diff2Html from "diff2html";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../api/global.env";
import "diff2html/bundles/css/diff2html.min.css";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      headBranch: null,
      branches: [],
      originalDiff: "",
      isLoading: false,
      diffFormat: "side-by-side",
      token: this.$store.state.Auth.gitToken,
      repoOwner: CryptoJS.AES.decrypt(
        this.$route.params.repouser,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      repoName: CryptoJS.AES.decrypt(
        this.$route.params.reponame,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      baseBranch: CryptoJS.AES.decrypt(
        this.$route.params.currentbranch,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
    };
  },
  computed: {
    filteredBranches() {
      return this.branches.filter(
        (branch) => branch.name !== this.baseBranch?.name
      );
    },
    computedDiffHtml() {
      if (!this.originalDiff) return "";
      return Diff2Html.html(this.originalDiff, {
        fileListToggle: true,
        drawFileList: true,
        fileListStartVisible: false,
        outputFormat: this.diffFormat,
        synchronisedScroll: true,
        highlight: true,
        highlightCode: true,
        diffMaxChanges: 200,
        matching: "words",
      });
    },
  },
  mounted() {
    this.fetchBranches();
  },
  methods: {
    messageToast(title, variant, content) {
      this.$bvToast.toast(content, { title, variant, solid: true });
    },
    toggleDiffView() {
      this.diffFormat =
        this.diffFormat === "side-by-side" ? "line-by-line" : "side-by-side";
    },
    async fetchBranches() {
      this.isLoading = true;
      try {
        const response = await this.$store.getters.client.get(
          `orguser/repobranches?repoUser=${this.repoOwner}&repoName=${this.repoName}`
        );
        this.branches = response.data.map((branch) => ({ name: branch.name }));
      } catch (error) {
        console.error("Error fetching branches:", error.response);
        this.messageToast(
          "Internal Server Error",
          "danger",
          "Error fetching branches"
        );
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDiff() {
      if (!this.baseBranch || !this.headBranch) {
        this.messageToast(
          "Branch Selection Required",
          "warning",
          "Please select both base and target branches."
        );
        return;
      }

      this.isLoading = true;

      try {
        const response = await this.$store.getters.client.get(
          `orguser/branch/diff?repoOwner=${this.repoOwner}&repoName=${this.repoName}&baseBranch=${this.baseBranch.name}&compareBranch=${this.headBranch.name}`
        );

        this.originalDiff = response.data?.data || "";
      } catch (error) {
        this.messageToast(
          "Internal Server Error",
          "danger",
          "Error fetching diff"
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.pr-comparison-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.page-heading {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
}

.pr-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.branch-label {
  font-weight: bold;
  color: black;
}

.branch-multiselect {
  width: 200px;
  color: black !important;
  background-color: white;
}

.arrow-icon {
  font-size: 18px;
  color: #666;
}

.compare-btn {
  background-color: #0f3460;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.compare-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.compare-btn i {
  font-size: 16px;
}

.custom-diff-container {
  margin-top: 20px;
  width: 100%;
  border: 1px solid #ccc;
  overflow: auto;
  padding: 1rem;
}

.loader-container {
  text-align: center;
  padding: 20px;
  color: #666;
}

.loader-container i {
  font-size: 36px;
  color: #0f3460;
}

.no-changes-container {
  text-align: center;
  padding: 50px;
  color: #666;
}

.no-changes-container i {
  font-size: 36px;
  color: #999;
}

.no-changes-container p {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}

.toggle-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f6f8fa;
  color: #fe5e45;
  border: 1px solid #d0d7de;
  padding: 4px 12px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s, border-color 0.2s;
  margin-left: auto;
}

.toggle-btn:hover {
  background-color: #eaeef2;
  border-color: #b1bac4;
}

.toggle-btn:active {
  background-color: #fe5e45;
}

.toggle-btn i {
  font-size: 1rem;
  color: #656d76;
}

.toggle-btn.active {
  background-color: #ddf4ff;
  border-color: #0f3460;
  color: #0f3460;
}
</style>
