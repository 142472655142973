<template>
  <b-alert variant="info" show v-if="isPublishedToChatbot" class="mt-2 mb-0">
    <p class="deploy-text">
      Your HTML output has been successfully published.
      <br />
      View it here:
      <a :href="ChatbotFrontendURL" target="_blank">{{ ChatbotFrontendURL }}</a>
    </p>
  </b-alert>
</template>

<script>
import { ChatbotFrontendURL } from "../api/global.env";

export default {
  data() {
    return {
      ChatbotFrontendURL: ChatbotFrontendURL,
      isPublishedToChatbot: false, // Local state for publishing status
    };
  },
  created() {
    // Listen for the event globally
    this.$root.$on("publishSuccess", this.handlePublishSuccess);
  },
  beforeDestroy() {
    // Cleanup event listener when the component is destroyed
    this.$root.$off("publishSuccess", this.handlePublishSuccess);
  },
  methods: {
    handlePublishSuccess() {
      this.isPublishedToChatbot = true;
    },
  },
};
</script>

<style scoped>
.deploy-text {
  font-weight: 400;
  font-size: 0.875rem;
  color: #17233d;
  line-height: 1.5;
  margin-top: 1rem;
}

.deploy-text a {
  color: inherit;
  font-size: 0.8rem;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  text-decoration: none;
  color: #fe5e45;
}

.deploy-text:hover a {
  color: #fe5e45cc;
  text-decoration: underline !important;
}
</style>
