<template>
  <div>
    <b-modal ref="deployToChatbotModal" hide-footer>
      <div>
        <div class="my-3" v-if="newOrExistingBranch == 'new'">
          <label>New Branch Name<span class="text-danger">*</span></label>
          <input
            class="form-control"
            id="newBranchName"
            v-model="selectedName"
          />
        </div>
        <div class="my-3" v-if="newOrExistingBranch == 'existing'">
          <label>Select Branch Name<span class="text-danger">*</span></label>

          <!-- Multiselect for project selection -->
          <multiselect
            v-model="selectedName"
            :options="repobranchesdata"
            placeholder="Choose a branch"
            :max-height="150"
          >
          </multiselect>
        </div>
        <div class="text-center">
          <!-- @click="createNewBranchHandler" -->
          <button
            class="btn btn-primary"
            :disabled="!selectedName"
            @click="deployToChatbotHandler()"
          >
            Deploy to chatbot
          </button>
        </div>
      </div>
    </b-modal>

    <!-- Progress Bar Modal -->
    <b-modal
      id="modal-progress"
      ref="modalProgress"
      title="Deploying..."
      title-class="font-18"
      hide-footer
      hide-close
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <div class="text-center">
        <p v-if="progress < 100">Deployment in progress...</p>
        <p v-else class="text-success">Deployment successful!</p>
      </div>
      <b-progress
        :value="progress"
        :max="100"
        class="mb-3 custom-progress"
      ></b-progress>
    </b-modal>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

export default {
  components: {
    Multiselect,
  },

  props: { userActivityId: String, repouser: String },
  data() {
    return {
      selectedName: null,
      newOrExistingBranch: null,
      repobranchesdata: [],
      progress: 0
    };
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    deployToChatbotSelectBranch() {
      Swal.fire({
        title: "Choose Existing or New Branch?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "New",
        cancelButtonText: "Extisting",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.selectedName = null;
          console.log("confirm new");
          this.newOrExistingBranch = "new";
          this.$refs["deployToChatbotModal"].show();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancle existing");
          await this.getRepoBranch();
          this.newOrExistingBranch = "existing";
          this.$refs["deployToChatbotModal"].show();
        }
      });
    },
    async getRepoBranch() {
      let loader = this.$loading.show({
        loader: "dots",
      });

      this.$store.getters.client
        .get(
          `orguser/repobranches?repoUser=${this.repouser}&repoName=AI-Deployment`
        )
        .then(async (branchResponse) => {
          if (branchResponse.data && branchResponse.data.length > 0) {
            this.repobranchesdata = branchResponse.data
              .map((element) => element.name)
              .filter((f) => f !== "main");

            if (this.repobranchesdata.length == 0) {
              this.messageToast(
                "No branch found",
                "danger",
                "No branch found in the repository."
              );
            }
          } else {
            this.messageToast(
              "No branch found",
              "danger",
              "No branch found in the repository."
            );
          }
        })
        .catch((error) => {
          this.messageToast(
            "invalid request",
            "danger",
            error.response.data.message
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
    async simulateProgress() {
      for (let i = 5; i <= 90; i += 2) {
        await new Promise((resolve) => setTimeout(resolve, 500));
        if (this.progress >= 100) break; 
        this.progress = i;
      }
    },
    async deployToChatbotHandler() {
      this.progress = 0;
      this.$refs["deployToChatbotModal"].hide();
      this.$refs.modalProgress.show(); 

      const deployToChatbotParams = {
        selectedName: this.selectedName,
        publishOption: this.newOrExistingBranch,
        userActivityId: this.userActivityId,
      };
      
      try {
        this.simulateProgress();

        const response = await this.$store.getters.client.post(
          `/orguser/workspace/publish-to-chatbot`,
          deployToChatbotParams
        );

        console.log("📢Publish Response", response);
        this.progress = 100; // Set progress to complete
        this.messageToast("Success", "primary", response.data.message);
        this.$refs["deployToChatbotModal"].hide();
        this.$emit("deleteUserActivityId");
        this.$root.$emit("publishSuccess");

        setTimeout(() => {
          this.$refs.modalProgress.hide();
        }, 1000);

      } catch (error) {
        this.messageToast("Error", "danger", error.response?.data?.message || "Deployment failed");
        this.progress = 0;
        this.$refs.modalProgress.hide();
      }
    },
  },
};
</script>

<style>
.custom-progress .progress-bar {
  background-color: #fe5e45 !important;
  border-radius: 5px;
}
</style>
