<template>
  <div>
    <!-- Header for the Navbar-Logo Configuration Section -->
    <h5 class="text-primary p-2 mb-2">Logo</h5>
    <div class="form-group row">
      <!-- Logo Image Input -->
      <b-col sm="6">
        <label for="logo-image">Logo</label>
        <input
          type="file"
          class="form-control-file logoFileInput"
          id="logo-image"
          name="logo-image"
          accept=".jpg, .jpeg, .png"
          ref="fileInput"
          @change="onFileSelected"
        />
        <span v-if="formDataList.header?.logoName" class="text-single-line">{{
          formDataList.header.logoName
        }}</span>
        <span v-else>no file chosen</span>
      </b-col>
    </div>
    <!-- Color Shades -->
    <h5 class="text-primary p-2">Color shades</h5>
    <div class="form-group row">
      <b-col sm="6 mt-2 mb-0">
        <input
          type="color"
          id="color"
          class="form-control"
          v-model="formDataList.header.headerColor"
        />
      </b-col>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <div class="d-flex">
        <div>
          <!-- Next Button -->
          <b-button
            size="sm"
            variant="primary"
            class="w-100 mb-2 ml-2"
            @click.prevent="nextForm()"
            >Next</b-button
          >
        </div>
      </div>
      <div class="d-flex">
        <div>
          <!-- Reset Button -->
          <b-button
            size="sm"
            variant="light"
            class="w-100 mb-2 mr-2"
            @click.prevent="refreshFormData()"
            >Reset</b-button
          >
        </div>
        <div>
          <!-- Submit Button -->
          <b-button
            size="sm"
            variant="primary"
            class="w-100 mb-2 ml-2"
            @click.prevent="confirm()"
            >Submit</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  props: {
    formDataList: {
      type: Object,
      required: true,
    },
    selectedLogoFile: File,
  },
  created() {
    this.updateFormData(this.formDataList);
  },
  beforeDestroy() {
    this.submitForm();
  },
  mounted() {
    const data = {
      title: "Header",
      paragraph:
        "You can use these fields to design the technical manual's front logo, color shades and title.",
      icons: "mdi mdi-page-layout-header font-size-26 align-middle",
    };
    this.$emit("data-mounted", data);
    // Set the selected file in the input if it exists
    if (this.selectedLogoFile) {
      this.$refs.fileInput.value = null; // Clear input value first to allow re-selection of same file
      this.$refs.fileInput.files = [this.selectedLogoFile];
    }
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    convertDataToLowerCase() {
      const dataKeys = Object.keys(this.$data);
      for (const key of dataKeys) {
        const obj = this.$data[key];
        if (typeof obj === "object" && obj !== null) {
          const keys = Object.keys(obj);
          for (const innerKey of keys) {
            if (obj[innerKey] !== null && typeof obj[innerKey] === "string") {
              obj[innerKey] = obj[innerKey].toLowerCase();
            }
          }
        }
      }
    },
    async confirm() {
      this.submitForm();
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary btn-sm mr-2",
          cancelButton: "btn btn-light btn-sm",
        },
        buttonsStyling: false,
      });
      await swalWithBootstrapButtons
        .fire({
          title: "Do you want to confirm changes?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.finalSubmit();
          }
        });
    },
    updateFormData(formDataList) {
      for (const key in formDataList) {
        if (Object.prototype.hasOwnProperty.call(formDataList, key)) {
          this[key] = formDataList[key];
        }
      }
    },
    refreshFormData() {
      this.$emit("reset-form");
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      if (file) {
        this.$set(this.formDataList.header, "logoName", file.name);
        this.$emit("fileSelected", file);
      }
    },
    nextForm() {
      this.$emit("next-form");
      this.submitForm();
      this.convertDataToLowerCase();
    },
    prevForm() {
      this.$emit("prev-form");
      this.submitForm(), this.convertDataToLowerCase();
    },
    finalSubmit() {
      this.$emit("final-submit"), this.convertDataToLowerCase();
    },
    submitForm() {
      this.$emit("form-submitnew", this.formDataList);
    },
  },
};
</script>
<style scoped>
label {
  font-weight: 400;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.star-rating {
  display: inline-block;
}

.star {
  color: orange;
  cursor: pointer;
  position: relative;
  top: -17px;
  bottom: 0px;
}

.filled {
  color: orange;
}

.form-group {
  margin: 10px;
}

.logoFileInput {
  color: rgba(0, 0, 0, 0);
}

.text-single-line {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 280px) {
  .btn {
    width: 20px;
    font-size: 8px;
    margin: 0;
    padding: 3px 4px;
  }

  * {
    font-size: 15px;
  }
}
</style>
