<template>
  <div>
    <!-- Progress Modal -->
    <b-modal
      id="modal-progress"
      ref="modaloutputprogress"
      hide-header
      title="Processing"
      title-class="font-18"
      hide-footer
      hide-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <strong>Please wait</strong>
      <br />
      <p>Working on customization to receive the desired output.</p>
      <b-progress
        :value="progress"
        :max="100"
        class="custom-progress"
      ></b-progress>
    </b-modal>
    <!-- Sidebar -->
    <div class="row">
      <div class="col-xl-3 col-lg-4 user-select-none">
        <div no-body>
          <div
            class="custom-html-styler d-flex justify-content-between align-items-center flex-wrap"
          >
            <div class="custom-title mb-0">PDF STYLER</div>
          </div>
          <div class="border-top">
            <div class="categories-group-card">
              <a
                class="categories-group-list card-title"
                :class="{
                  active: currentFormNumber === 1,
                  '': currentFormNumber === 1,
                }"
                @click="changeForm(1)"
              >
                <i class="mdi mdi-home font-size-18 align-middle mr-2"></i>
                Front Page
              </a>
            </div>
            <div class="categories-group-card">
              <a
                class="categories-group-list card-title"
                :class="{
                  active: currentFormNumber === 2,
                  '': currentFormNumber === 2,
                }"
                @click="changeForm(2)"
              >
                <i class="mdi mdi-home font-size-18 align-middle mr-2"></i>
                Base Color
              </a>
            </div>
            <div class="categories-group-card">
              <a
                class="categories-group-list card-title"
                :class="{
                  active: currentFormNumber === 3,
                  '': currentFormNumber === 3,
                }"
                @click="changeForm(3)"
              >
                <i class="mdi mdi-home font-size-18 align-middle mr-2"></i>
                Header & Footer
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- Main Content Area -->
      <div class="col-lg-9 border-left">
        <div no-body>
          <div
            class="custom-html-styler d-flex justify-content-between align-items-center flex-wrap"
          >
            <div class="custom-title mb-0">
              <span class="text-uppercase">{{ childData.title }}</span>
            </div>
          </div>
          <div class="mx-1 border-top">
            <b-card-sub-title class="mt-3 mb-4">
              <h6 class="card-subtitle text-center">
                {{ childData.paragraph }}
              </h6>
            </b-card-sub-title>
            <!-- Conditional rendering of form components -->
            <div
              v-if="currentFormNumber > 0 && currentFormNumber <= totalForms"
            >
              <!-- Dynamic Form Component -->
              <component
                :formDataList="formDataList"
                :is="currentForm"
                :form-number="currentFormNumber"
                :selectedLogoFile="selectedLogoFile"
                @form-submitnew="handleformSubmit"
                @data-mounted="handleChildData"
                @next-form="handlenextbtn"
                @prev-form="handlePreviousBtn"
                @final-submit="finalSubmit"
                @reset-form="resetForm"
                @fileSelected="handleFileSelected"
              ></component>
            </div>
            <div v-else>
              <p>No form found</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import Swal from "sweetalert2";
import baseColor from "./components/baseColor.vue";
import frontpage from "./components/frontpage.vue";
import headerfooter from "./components/headerfooter.vue";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../../api/global.env";
export default {
  components: {
    frontpage,
    baseColor,
    headerfooter,
  },
  data() {
    return {
      progress: 0,
      condition: true,
      currentFormNumber: 1,
      totalForms: 3,
      formDataList: {
        color: "#000000",
        footer: {
          companyName: "",
        },
      },
      childData: {},
      userId: this.$store.state.Auth.userId,
      orgId: this.$store.state.Auth.orgId,
      projectName: CryptoJS.AES.decrypt(
        this.$route.params.reponame,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      repouser: CryptoJS.AES.decrypt(
        this.$route.params.repouser,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      brachName: CryptoJS.AES.decrypt(
        this.$route.params.repobranch,
        secretKey
      ).toString(CryptoJS.enc.Utf8),
      selectedLogoFile: [],
    };
  },
  computed: {
    _() {
      return _;
    },
    currentForm() {
      switch (this.currentFormNumber) {
        case 1:
          return "frontpage";
        case 2:
          return "baseColor";
        case 3:
          return "headerfooter";
        default:
          return null;
      }
    },
  },
  methods: {
    handleChildData(data) {
      this.childData = data;
    },
    async defaultalert() {
      if (this.condition) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-primary btn-sm mr-2",
            cancelButton: "btn btn-light btn-sm",
          },
          buttonsStyling: false,
        });
        await swalWithBootstrapButtons
          .fire({
            title: "Do you want to confirm changes?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirm",
            cancelButtonText: "No",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.handledefualt();
            }
          });
      }
    },
    resetForm() {
      this.formDataList = {
        color: "#000000",
        footer: {
          companyName: "",
        },
      };
      console.log("reset: ", this.selectedLogoFile)
      this.selectedLogoFile = [];
      console.log("after reset: ", this.selectedLogoFile)
    },
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    changeForm(formNumber) {
      this.currentFormNumber = formNumber;
    },
    handleformSubmit(formData) {
      this.formDataList = JSON.parse(
        JSON.stringify({
          ...this.formDataList,
          ...formData,
        })
      );
    },
    handlenextbtn() {
      if (this.currentFormNumber < this.totalForms) {
        this.currentFormNumber++;
      }
    },
    handlePreviousBtn() {
      if (this.currentFormNumber > 1) {
        this.currentFormNumber--;
      }
    },
    redirectDocPublishrer() {
      const encodedRepouser = encodeURIComponent(this.$route.params.repouser);
      const encodedReponame = encodeURIComponent(this.$route.params.reponame);
      const encodedBranch = encodeURIComponent(this.$route.params.repobranch);
      this.$router.push({
        path: `/pdfdocstyler/docpublisher/${encodedRepouser}/${encodedReponame}/${encodedBranch}`,
      });
    },

    async saveLogoImage(fileArray) {
      const formData = new FormData();
      for (const file of fileArray) {
        formData.append(file.type, file.file);
      }
      const userId = this.$store.state.Auth.userId;
      const orgId = this.$store.state.Auth.orgId;
      await this.$store.getters.client.post(
        `/orguser/docstyler/uploadPdfLogo?userId=${userId}&orgId=${orgId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },

    async finalSubmit() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      try {
        if (this.selectedLogoFile.length > 0) {
          await this.saveLogoImage(this.selectedLogoFile);
        }
        const body = {
          userId: this.userId,
          orgId: this.orgId,
          customizationOptions: this.formDataList,
        };
        await this.$store.getters.client.post(
          `/orguser/docstyler/customizePdfOutput`,
          body
        );
        this.messageToast("Success", "primary", "Changes made successfully.");
        setTimeout(() => {
          this.redirectDocPublishrer();
        }, 1500);
      } catch (error) {
        console.log("📢[index.vue:412]: error: ", error);
        const resMsg = error.response
          ? error.response.data.message
          : error.message
          ? error.message
          : "Error in customizing the output";
        this.messageToast("Invalid request", "danger", resMsg);
      } finally {
        loader.hide();
      }
    },
    handledefualt() {
      const body = {
        userId: this.userId,
        orgId: this.orgId,
        customizationOptions: {},
      };
      this.$store.getters.client
        .post(`/orguser/docstyler/customizePdfOutput`, body)
        .then(() => {
          this.redirectDocPublishrer();
          this.messageToast(
            "Success",
            "primary",
            "Your DITA-OT has been reset to its default settings."
          );
        })
        .catch((err) => {
          this.messageToast("Invalid request", "danger", err.data.message);
        });
    },
    handleFileSelected(fileObj) {
      const existingIndex = this.selectedLogoFile.findIndex(
        (item) => item.type === fileObj.type
      );

      if (existingIndex !== -1) {
        this.selectedLogoFile.splice(existingIndex, 1, fileObj);
      } else {
        this.selectedLogoFile.push(fileObj);
      }
    },
  },
};
</script>
<style scoped>
.custom-html-styler {
  padding: 14px;
  gap: 24px;
}

.custom-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}

.custon-btn {
  background: #2b313f !important;
  color: #fff !important;
  border-color: #2b313f !important;
  width: 90px;
  height: 30px;
}

.btn-info {
  width: 130px;

  height: 30px;
}

.btn-container {
  display: flex;
  overflow-x: scroll;
}

.card-btn {
  margin: 10px;
  float: right;
}

.categories-group-list {
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.categories-group-list.active {
  color: rgba(254, 94, 69, 1);
}

.bg-card {
  background-color: rgb(95, 163, 240) !important;
  margin-bottom: 0px;
  padding: 0px;
}

.bg-navbar {
  background-color: rgb(95, 163, 240) !important;
  margin-bottom: 1vh;
  padding: 0px;
}

.bg-header {
  background-color: rgb(95, 163, 240) !important;
  margin-bottom: 1vh;
  padding: 0px;
}

.custom-header {
  background-color: rgb(95, 163, 240) !important;
  margin-bottom: 0px;
  padding: 0px;
}

.headers-icons {
  color: #ffffff;
  font-size: 30px;
  margin-right: 5px;
}

.card-title {
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.card-titleNavbar {
  font-size: 15px;
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.card-subtitle {
  margin-bottom: -10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.form-header {
  margin: -12px 0 -6px 0;
}

.custom-progress {
  height: 5px;
}
</style>
