<template>
  <div>
    <h5 class="mb-2 text-primary p-2 title">Footer</h5>
    <div class="form-group row">
      <b-col sm="12 mt-2 mb-0">
        <label for="facebook">Company Name</label>
        <input
          v-model="formDataList.footer.companyName"
          @keydown.space="preventLeadingSpace"
          name="companyName"
          type="text"
          class="form-control"
          id="companyName"
          placeholder="Enter company name"
        />
      </b-col>
    </div>
    <div class="form-group row">
      <b-col sm="12 mt-2 mb-0">
        <label for="facebook">Company website</label>
        <input
          v-model="formDataList.footer.companyWebsite"
          @keydown.space="preventLeadingSpace"
          name="companyWebsite"
          type="text"
          class="form-control"
          id="companyWebsite"
          placeholder="Enter company website"
        />
      </b-col>
    </div>
    <div class="d-flex justify-content-between mt-3">
      <div class="d-flex">
        <div>
          <!-- Reset Button -->
          <b-button
            size="sm"
            variant="light"
            class="w-100 mb-2 mr-2"
            @click.prevent="prevForm"
            >Previous</b-button
          >
        </div>
      </div>
      <div class="d-flex">
        <div>
          <!-- Reset Button -->
          <b-button
            size="sm"
            variant="light"
            class="w-100 mb-2 mr-2"
            @click.prevent="refreshFormData"
            >Reset</b-button
          >
        </div>
        <div>
          <!-- Submit Button -->
          <b-button
            size="sm"
            variant="primary"
            class="w-100 mb-2 ml-2"
            @click.prevent="confirm"
            >Submit</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import _ from "lodash";
export default {
  props: {
    formDataList: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      websiteError: "",
    };
  },
  computed: {
    _() {
      return _;
    },
  },
  created() {
    this.updateFormData(this.formDataList);
  },
  beforeDestroy() {
    this.submitForm();
  },
  mounted() {
    const data = {
      title: "Footer",
      paragraph:
        "You can use these fields to customize the technical manual's footer.",
      icons: "mdi mdi-table font-size-26 align-middle mr-2",
    };
    this.$emit("data-mounted", data);
  },
  watch: {
    $data: {
      deep: true,
      handler() {
        this.convertDataToLowerCase();
      },
    },
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    convertDataToLowerCase() {
      const dataKeys = Object.keys(this.$data);
      for (const key of dataKeys) {
        const obj = this.$data[key];
        if (typeof obj === "object" && obj !== null) {
          const keys = Object.keys(obj);
          for (const innerKey of keys) {
            if (obj[innerKey] !== null && typeof obj[innerKey] === "string") {
              obj[innerKey] = obj[innerKey].toLowerCase();
            }
          }
        }
      }
    },
    async confirm() {
      this.submitForm();
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-primary btn-sm mr-2",
          cancelButton: "btn btn-light btn-sm",
        },
        buttonsStyling: false,
      });
      await swalWithBootstrapButtons
        .fire({
          title: "Do you want to confirm changes?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.finalSubmit();
          }
        });
    },
    updateFormData(formDataList) {
      for (const key in formDataList) {
        if (Object.prototype.hasOwnProperty.call(formDataList, key)) {
          this[key] = formDataList[key];
        }
      }
    },
    nextForm() {
      this.$emit("next-form");
      this.submitForm(), this.convertDataToLowerCase();
    },
    prevForm() {
      this.$emit("prev-form");
      this.submitForm(), this.convertDataToLowerCase();
    },
    finalSubmit() {
      this.$emit("final-submit"), this.convertDataToLowerCase();
    },
    submitForm() {
      const formData = {
        footer: this.formDataList.footer,
      };
      this.$emit("form-submitnew", formData);
    },
    refreshFormData() {
      this.$emit("reset-form");
    },
  },
};
</script>
<style scoped>
label {
  font-weight: 400;
}

h1 {
  text-decoration: wavy;
}

.form-group {
  margin: 10px;
}
</style>
